import React from 'react'
import { getUrlMedia } from '../../../../helpers'
import './card-style.scss'

const Card = ({
  url, image, title, name,
}) => {
  const titleCard = title || name
  const urlImage = url || image
  return (
    <div
      className="cardCareers__container"
      style={{
        backgroundImage: `url(${getUrlMedia(urlImage)})`,
      }}
    >
      <p>{titleCard}</p>
    </div>
  )
}

export default Card
