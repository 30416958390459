/* eslint-disable max-len */
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  Testimonials,
  Cta,
  CarouselCards,
  ImageRoundCorners,
  Spinner,
  Seo,
} from '../../components'
import { getUrlMedia } from '../../helpers'
import { Card } from './components'
import './careers-style.scss'
import imageBgCta from '../../assets/images/home/Join_Our_Team.jpeg'

const CareersView = ({ loading, data }) => {
  const [lessText, setLessText] = useState(true)
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="container-fluid careers__container">
      <Seo title={`${t('seo.title')} Careers`} />
      <div className="careers__head row">
        <div className="col-12 col-md-7">
          {/* <div className="appendix">
              <div className="heroImage__image"> */}
          <ImageRoundCorners
            addon={true}
            src={getUrlMedia(_.get(data, 'banner_section[0].image'))}
            roundBottomRightHoz={true}
          />
          {/* </div>
            </div> */}
        </div>
        <div className="col-12 col-md-5 careers__head__content">
          <h1 className="page-title">
            {_.get(data, 'banner_section[0].title')}
          </h1>
          {lessText ? (
            <div onClick={() => setLessText(!lessText)} className="">
              <HTMLEllipsis
                unsafeHTML={_.get(data, 'banner_section[0].description')}
                maxLine={breakpoints.xs ? '3' : '1000'}
                ellipsis={' Read more'}
                trimRight
                basedOn="letters"
              />
            </div>
          ) : (
            <p>{_.get(data, 'banner_section[0].description')}</p>
          )}
        </div>
      </div>

      <div className="careers__departments">
        {_.get(data, 'departments', undefined) && <CarouselCards
          data={_.get(data, 'departments')}
          arrows={false}
          inner={Card}
          title={t('careers.departments')}
        /> }
      </div>

      <div className="careers__testimonials">
        <Testimonials dots={false} data={_.get(data, 'testimonials')} />
      </div>
      <div className="container-xxl p-0">
        <div className="careers__offer row">
          <div className="col-12 col-md-6">
            <div className="careers__departments__content">
              <h2>{t('careers.what')}</h2>
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{
                  __html: _.get(data, 'what_we_offer[0].description'),
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end">
            <ImageRoundCorners
              src={getUrlMedia(_.get(data, 'what_we_offer[0].image'))}
              roundBottomLeft={true}
            />
          </div>
        </div>
        <div className="careers__cta">
          <Cta
            externalLink={_.get(data, 'external_link')}
            img={imageBgCta}
            info={{
              title: t('careers.cta.title'),
              buttonText: t('careers.cta.button'),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CareersView
